.testimonial-card {
  padding: 24px;
  background: var(--background);
  border-radius: 20px;
  height: 100%;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.14);
  overflow: hidden;
  background: var(--tertiary-background);
}
.testimonial-quote {
  height: 24px;
  color: ;
}

.reviews-profile {
  background: var(--tertiary-color);
  width: 32px;
  height: 32px;
  border-radius: 100%;
  font-weight: 800;
  color: var(--tertiary-background);
}

.star {
  height: 24px;
  width: 24px;
}
.reviews-profile {
  margin: 16px 16px 24px 0;
}

@media screen and (min-width: 650px) {
  .testimonial-quote {
    height: 32px;
  }
  .reviews-profile {
    height: 48px;
    width: 48px;
  }
}

@media screen and (min-width: 1200px) {
  .testimonial-quote {
    height: 48px;
  }
  .reviews-profile {
    height: 56px;
    width: 56px;
  }
}

@media screen and (min-width: 1700px) {
  .testimonial-quote {
    height: 56px;
  }
  .reviews-profile {
    height: 64px;
    width: 64px;
  }
}
