.contact-section-wrap {
  text-align: center;
}

.contact-section-wrap .section-header {
  margin: auto;
  max-width: 600px;
}
#contact .header-2 {
  margin-bottom: 12px;
}
