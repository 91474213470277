.test-button-wrap {
  height: 100%;
  width: 100%;
  /* display: block; */
  box-sizing: border-box;
  /* border: 1px solid red; */
  display: none;
}
.test-button-wrap button {
  padding: 0;
}
.test-button-wrap button {
  margin-left: 16px;
}
.test-button-wrap button svg {
  height: 24px;
  width: 24px;
  margin: 0;
}

.test-cards-wrap > *:not(:last-child) {
  margin-right: 24px;
}

.horizontalSlider___281Ls {
  overflow: visible;
}
#testimonials .horizontalSlider___281Ls li {
  margin-right: 24px;
  width: Calc(16.66% - 24px) !important;
  padding-bottom: min(20%, 300px) !important;
}

.car-button {
  display: none;
}
.testimonial-card-end {
  text-align: center;
  transition: 0.2s ease;
  cursor: pointer;
}
.testimonial-card-end:hover {
  transform: scale(1.02);
}
.testimonial-card-end button:hover {
  transform: scale(1) !important;
}

#testimonials .sliderAnimation___300FY > * {
  opacity: 0;
  transform: translatey(80px);
  transition: 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
  transition-delay: 0.45s;
}
#testimonials .sliderAnimation___300FY > *:nth-child(1) {
  transition-delay: 0s;
}
#testimonials .sliderAnimation___300FY > *:nth-child(2) {
  transition-delay: 0.2s;
}
#testimonials .sliderAnimation___300FY > *:nth-child(3) {
  transition-delay: 0.3s;
}
#testimonials .sliderAnimation___300FY > *:nth-child(4) {
  transition-delay: 0.4s;
}

#testimonials.active-test .sliderAnimation___300FY > * {
  opacity: 1;
  transform: translatey(0);
}
.test-cards-wrap {
  margin-top: -36px;
}

@media screen and (min-width: 650px) {
  .test-button-wrap {
    display: flex;
  }
  .test-button-wrap button {
    height: 48px;
    width: 48px;
  }
  #testimonials .horizontalSlider___281Ls li {
    padding-bottom: min(25%, 370px) !important;
  }
  .test-cards-wrap {
    margin-top: -24px;
  }
}
@media screen and (min-width: 900px) {
  .test-cards-wrap {
    margin-top: 0;
  }
}
@media screen and (min-width: 1080px) {
  #testimonials .horizontalSlider___281Ls li {
    padding-bottom: 20% !important;
  }
}
@media screen and (min-width: 1200px) {
  .test-button-wrap button {
    height: 56px;
    width: 56px;
    width: 56px;
  }
  .test-button-wrap button svg {
    height: 32px;
    width: 32px;
  }
  #testimonials .horizontalSlider___281Ls li {
    padding-bottom: min(20%, 330px) !important;
  }
  #testimonials {
    padding-bottom: 36px;
  }
}

@media screen and (min-width: 1700px) {
  .test-button-wrap button {
    height: 64px;
    width: 64px;
  }
  .test-button-wrap button {
    margin-left: 24px;
  }
  .test-button-wrap button svg {
    height: 48px;
    width: 48px;
  }
  #testimonials .horizontalSlider___281Ls li {
    padding-bottom: min(20%, 400px) !important;
  }
}
