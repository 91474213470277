.links-cont {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 15;

  background: var(--tertiary-background);
  box-sizing: border-box;

  padding: 18px 32px;
  border-radius: 40px 0 0 40px;
  margin-bottom: -36px;
  overflow: hidden;
}

.links-cont-headr {
  text-align: left;
  margin-bottom: 8px;
  font-weight: 700;
  opacity: 0.5;
  display: none;
}

.links-wrap > *:not(:last-child) {
  margin-right: 16px;
}

.links-wrap > * {
  opacity: 0;
  transform: translatex(24px) translatey(16px);
  transition: 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}
.links-wrap > *:nth-child(1) {
  transition-delay: 0s;
}
.links-wrap > *:nth-child(2) {
  transition-delay: 0.2s;
}
.links-wrap > *:nth-child(3) {
  transition-delay: 0.3s;
}
.links-wrap > *:nth-child(4) {
  transition-delay: 0.4s;
}

.links-wrap.show-icons > * {
  opacity: 1;
  transform: translatey(0);
}

@media only screen and (min-width: 600px) {
  .links-cont {
    border-radius: 36px 0 0 36px;
    padding: 24px 36px;
    margin-bottom: -44px;
  }
  .links-wrap > *:not(:last-child) {
    margin-right: 24px;
  }
  .links-cont-headr {
    margin-bottom: 16px;
  }
}

@media only screen and (min-width: 900px) {
  .links-cont {
    border-radius: 36px 0 0 36px;
    padding: 24px 36px;
    margin-bottom: -48px;
  }
  .links-wrap > *:not(:last-child) {
    margin-right: 36px;
  }
  .links-cont-headr {
    margin-bottom: 16px;
  }
}

@media only screen and (min-width: 1200px) {
  .links-cont {
    border-radius: 36px 0 0 36px;
    padding: 24px 48px;
    margin-bottom: -52px;
  }
  .links-wrap > *:not(:last-child) {
    margin-right: 36px;
  }
  .links-cont-headr {
    margin-bottom: 16px;
  }
}

@media only screen and (min-width: 1700px) {
  .links-cont {
    border-radius: 40px 0 0 40px;
    padding: 24px 48px;
  }
  .links-wrap > *:not(:last-child) {
    margin-right: 36px;
  }
  .links-cont-headr {
    margin-bottom: 48px;
  }
}
