.contact-input-wrap {
  transition: 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
  border-radius: 100px;
  box-sizing: border-box;
  margin: 48px 0;
  padding: 12px 12px 12px 32px;
  width: 756px;
  cursor: pointer;
  transform: scale(0.9);
  background: var(--tertiary-background);
}

#contact .active-test .contact-input-wrap {
  transform: scale(1);
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.14);
}
.contact-input-wrap:hover,
.active-input {
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2) !important;
}

.contact-input-wrap button {
  min-height: 48px;
}
.contact-input-wrap input {
  outline: none;
  border: none;
  background: none;
  margin-right: 8px;
  width: 100%;
  cursor: pointer;
}

.contact-input-wrap input::placeholder {
}

@media screen and (min-width: 650px) {
  .contact-input-wrap {
    padding: 16px 16px 16px 32px;
  }
  .contact-input-wrap button {
    min-height: 56px;
  }
}
