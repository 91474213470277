#products {
  background: var(--tertiary-background);
}
.products-more-button {
  margin: 24px auto;
}

@media only screen and (min-width: 900px) {
  .products-more-button {
    height: 56px;
  }
}
