.service-cards-cont {
  position: relative;
  padding: 0 0 72px 16px;
  overflow: hidden;
  width: Calc(100% + 32px);
  margin: 0 0 -24px -16px;
  overflow-x: auto;
}
.service-cards-wrap {
  grid-gap: 24px;
  min-width: 994px;
  display: flex;
  flex-direction: row;
}

.horizontalSlider___281Ls {
  overflow: visible !important;
}
#services .carousel {
  padding: 0 8px;
  margin: -24px 0 0;
}
#services .horizontalSlider___281Ls li {
  margin-right: 24px;
  width: Calc(14.27% - 24px) !important;
  padding-bottom: min(20%, 300px) !important;
  /* transition: 0.3s cubic-bezier(0.34, 1.56, 0.64, 1); */
}

#services .carousel ul > .carousel__slide--visible a {
  box-shadow: 0px 20px 40px rgb(0 0 0 / 14%);
  transform: scale(1.05);
}
#services .carousel ul > .carousel__slide--visible button {
  background: var(--highlight);
  color: var(--tertiary-background);
}
#services .carousel ul > .carousel__slide--visible .services-icon-wrap {
  background: var(--tertiary-color);
}
#services
  .carousel
  ul
  > .carousel__slide--visible
  ~ .carousel__slide--visible
  a {
  box-shadow: none;
  transform: scale(1);
}
#services
  .carousel
  ul
  > .carousel__slide--visible
  ~ .carousel__slide--visible
  button {
  background: none;
  color: var(--highlight);
}
#services
  .carousel
  ul
  > .carousel__slide--visible
  ~ .carousel__slide--visible
  .services-icon-wrap {
  background: var(--tertiary-color-light);
  /* color: var(--highlight); */
}
#services .testimonial-card {
  box-shadow: none;
}

#services .sliderAnimation___300FY > * {
  opacity: 0;
  transform: translatey(80px);
  transition: 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
  transition-delay: 0.45s;
}

#services .sliderAnimation___300FY > *:nth-child(1) {
  transition-delay: 0s;
}
#services .sliderAnimation___300FY > *:nth-child(2) {
  transition-delay: 0.2s;
}
#services .sliderAnimation___300FY > *:nth-child(3) {
  transition-delay: 0.3s;
}
#services .sliderAnimation___300FY > *:nth-child(4) {
  transition-delay: 0.4s;
}

#services .active-test .sliderAnimation___300FY > * {
  opacity: 1;
  transform: translatey(0);
}
.test-cards-wrap {
  margin-top: -36px;
}

a.service-card-more-link {
  background: none !important;
  overflow: visible !important;
  box-shadow: none !important;
}
button.mobile-services-button {
  /* height: 48px;
  width: 48px; */
  /* padding: 0; */
  box-shadow: none !important;
  background: none !important;
  transform: scale(1) !important;
  /* color: #b9cad4; */
  color: black;
  opacity: 0.15;
}
button.mobile-services-button:hover {
  opacity: 1 !important;
}

/* button.mobile-services-button span {
  opacity: 1;
  text-transform: none;
  display: block;
  padding-right: 42px;
  font-size: 16px;
  height: 48px;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
} */
button.mobile-services-button svg {
  margin: 0 !important;
  height: 24px !important;
  width: 24px !important;
  /* position: absolute;
  animation: drop-right ease-in 0.8s alternate infinite; */
}
.hint-wrapper-services {
  width: 96%;
  margin-bottom: -16px;
  padding-top: 16px;
}
@media only screen and (min-width: 600px) {
  #services {
    padding-bottom: 24px;
  }
  #services .horizontalSlider___281Ls li {
    margin-right: 24px;
    padding-bottom: min(20%, 360px) !important;
  }
  #services .carousel {
    padding: 0 0 0 16;
    margin: -16px 0 0;
  }
  .hint-wrapper-services {
    margin: 24px 0 -24px;
    width: 48%;
  }
}

@media only screen and (min-width: 900px) {
  #services {
    /* padding-bottom: 32px; */
  }
  #services .carousel {
    padding: 0 0 0 24px;
    margin: 0;
  }
  .hint-wrapper-services {
    margin: 24px 0 -48px;
    width: 35%;
  }
}
@media only screen and (min-width: 1200px) {
  #services {
    padding-bottom: 48px;
  }
}
@media only screen and (min-width: 1700px) {
  #services {
    padding-bottom: 48px;
  }
  #services .carousel {
    padding: 0 0 0 0;
    margin: 0;
  }
  .hint-wrapper-services {
    margin: 24px 0 -48px -24px;
    width: 35%;
  }
}
