#footer {
  background: var(--tertiary-background);
}
.footer-brand {
  max-width: 280px;
}
.footer-content > * {
  flex-direction: column;
  align-items: center;
}
.footer-content > * div {
  width: 100%;
  align-items: center;
}

.footer-brand {
  margin-bottom: 24px;
}

.footer-brand svg {
  height: 48px;
  width: 48px;
  margin-bottom: 16px;
}

.footer-content-section p {
  display: flex;
  align-items: center;
  height: 32px;
  margin-bottom: 24px;
}
.footer-content-section a {
  height: 42px;
  transition: 0.15s ease;
}
.footer-content-section a:hover {
  color: var(--primary-font);
}

footer {
  background: black;
  padding-top: 0 !important;
  color: white;
  overflow: hidden;
}
footer > * {
  gap: 16px;
  flex-direction: column-reverse;
  text-align: center;
}
.footer-social-piller,
.footer-piller,
.footer-com {
  display: none;
}

.footer-social-links > *:not(:last-child) {
  margin-right: 8px;
}

.footer-social-links a {
  transition: 0.15s;
  background: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 6px;
  display: block;
  box-sizing: border-box;
}
.footer-social-links a:hover {
  background: rgba(255, 255, 255, 0.1);
}
.footer-social-links a svg {
  display: block;
  width: 24px;
  color: white;
  height: 24px;
}
footer > * div:first-of-type {
  font-size: 14px;
}

@media only screen and (min-width: 600px) {
  .footer-content > * {
    gap: 32px;
    flex-direction: row;
    align-items: flex-start;
    padding-bottom: 24px;
  }
  .footer-content > * div {
    text-align: left;
    align-items: flex-start;
  }

  .footer-brand {
    margin-bottom: 0;
  }
  .footer-brand svg {
    margin-bottom: 24px;
  }
  .footer-content-sections-wrapper {
    gap: 48px;
    width: 100%;
    max-width: 400px;
  }
  .footer-content-section p {
    height: 48px;
    margin-bottom: 32px;
  }
  .footer-piller {
    display: flex;
  }
  .footer-com {
    display: block;
  }
  footer {
    height: 48px;
  }
  footer > * {
    gap: 0;
    flex-direction: row;
    text-align: left;
  }
}

@media only screen and (min-width: 900px) {
  .footer-social-piller {
    display: flex;
  }
}
