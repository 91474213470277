.landing-section {
  height: min(100vh, 200vw);
  /* min-height: Calc(100vw * 0.67); */
  box-sizing: border-box;
  padding-top: 56px;
  max-height: 1272px;
  position: relative;
}

.landing-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  width: 100%;
}
.landing-wrapper > * {
  position: absolute;
  bottom: 24px;
  width: Calc(100% - 32px);
  height: 50%;
  text-align: left;
}

.landing-img {
  position: absolute;
  top: 56px;
  z-index: 1;
  width: 110%;
  /* height: 100vw; */
  height: 50%;
  height: 52%;
  object-fit: cover;
}

.landing-button-wrap {
  flex-wrap: wrap;
  margin-top: 32px;
  row-gap: 8px;
}

.landing-button-wrap button {
  height: 42px;
}
.landing-button-wrap > *:first-child {
  margin-right: 8px;
}

.landing-script {
  width: 120%;
  margin: -12% 0 -25% -7%;
}

#landing-button-arrow {
  display: none;
}

@media only screen and (min-width: 380px) {
  #landing-button-arrow {
    display: block;
  }
}

@media only screen and (min-width: 700px) {
  .landing-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
  }
  .landing-wrapper > * {
    position: relative;
    bottom: 0;

    width: 50%;
    height: auto;
    text-align: left;
  }

  .landing-img {
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
  }
  .landing-button-wrap {
    row-gap: 12px;
  }

  .landing-button-wrap button {
    height: 42px;
  }
  .landing-button-wrap > *:first-child {
    margin-right: 16px;
  }

  .landing-script {
    width: 150%;
    margin: 0 0 -30% -16%;
  }
}

@media only screen and (min-width: 900px) {
  .landing-section {
    padding-top: 80px;
  }
  .landing-button-wrap button {
    height: 56px;
    padding: 10px 32px;
    font-size: 18px;
  }
  .landing-button-wrap > *:first-child {
    margin-right: 24px;
  }
}

@media only screen and (min-width: 1200px) {
}
