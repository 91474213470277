a.service-card-wrapper {
  background: var(--background);
  border-radius: 30px;
  padding: 32px;
  width: 100%;
  height: 100%;
  display: block;
  box-sizing: border-box;
  transition: 0.2s ease;
  box-sizing: border-box;
  overflow: hidden;
  background: var(--tertiary-background);
}
.active-service-card button,
.active-service-card .services-icon-wrap {
  transition: 0.2s ease;
}

.active-service-card {
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.08);
  transform: scale(1.05);
}

.active-service-card .services-icon-wrap {
  background: #b9cad4;
}

.active-service-card button {
  background: var(--highlight);
  color: var(--tertiary-background);
}

.services-icon-wrap {
  width: 56px;
  height: 56px;
  border-radius: 16px;
  background: var(--tertiary-color-light);
  color: white;
}
.services-icon-wrap svg {
  height: 36px;
}
.service-card-wrapper h4 {
  margin: 24px 0 4px;
}
.service-card-wrapper p {
  margin: 0 0 24px;
}
@media only screen and (min-width: 600px) {
  a.service-card-wrapper {
    /* width: 320px;
    min-width: 320px;
    min-height: 320px; */
    border-radius: 25px;
  }
}
@media only screen and (min-width: 900px) {
  a.service-card-wrapper {
    /* width: 320px;
    min-height: 320px; */
    border-radius: 25px;
  }
  .services-icon-wrap {
    width: 64px;
    height: 64px;
    border-radius: 16px;
  }
  .services-icon-wrap svg {
    height: 48px;
  }
}
@media only screen and (min-width: 1200px) {
  a.service-card-wrapper {
    /* width: 370px; */
    /* height: 370px;
    padding: 48px; */
    border-radius: 30px;
  }
}
@media only screen and (min-width: 1700px) {
  a.service-card-wrapper {
    padding: 48px;
    /* width: 390px;
    height: 390px; */
  }
}
