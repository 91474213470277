/* @import url("https://rsms.me/inter/inter.css");
html {
  font-family: "Inter", sans-serif;
} */

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
html,
body {
  font-family: "Roboto", sans-serif;
}

h1 {
  font-weight: 900;
  font-size: 38px;
  line-height: 1.05;
  margin: 0;
}
h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.45;
  margin: 0;
}
h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 1.3;
  margin: 0;
}
h4,
.reviews-profile {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.45;
  margin: 0;
}
h5 {
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 1.5;
  margin: 0;
}
h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  margin: 0;
}
input,
p,
textarea,
button,
.nav-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.6;
  margin: 0;
}

a {
  font-weight: 400;
}
button,
.nav-text {
  font-weight: 600 !important;
}
.header-2 {
  cursor: default;
  font-size: 32px;
  margin-bottom: 4px;
  font-weight: 800;
  line-height: 1.3;
  text-transform: capitalize;
}
.header-text {
  font-size: 18px;
  color: var(--secondary-font);
}
.header-3 {
  font-style: normal;
  font-size: 20px;
  line-height: 1.45;
  margin: 0;
  font-weight: 700;
}

@media screen and (min-width: 650px) {
  h1 {
    font-size: 56px;
  }
  h2,
  h4,
  .reviews-profile {
    font-size: 24px;
  }
  h3 {
    font-size: 40px;
  }
  h5,
  h6 {
    font-size: 20px;
  }
  input,
  p,
  textarea,
  button,
  .nav-text {
    font-size: 18px;
  }
  .header-2 {
    font-size: 40px;
    margin-bottom: 4px;
  }
  .header-text {
    font-size: 20px;
  }
  .header-3 {
    font-size: 24px;
  }
}
@media screen and (min-width: 1200px) {
  h1 {
    font-size: 64px;
  }
  h2,
  h4,
  .reviews-profile {
    font-size: 26px;
  }
  h3 {
    font-size: 48px;
  }

  h5,
  h6 {
    font-size: 22px;
  }
  input,
  textarea,
  p {
    font-size: 20px;
  }
  .header-2 {
    font-size: 48px;
    margin-bottom: 4px;
  }
  .header-text {
  }
  .header-3 {
    font-size: 32px;
  }
}

@media screen and (min-width: 1700px) {
  h1 {
    font-size: 80px;
  }
  h2,
  h4,
  .reviews-profile {
    font-size: 32px;
  }
  h3 {
    font-size: 56px;
  }

  h5,
  h6 {
    font-size: 24px;
  }
  input,
  textarea,
  p {
    font-size: 22px;
  }

  button,
  .nav-text {
    font-size: 20px;
  }
  .header-2 {
    font-size: 56px;
    margin-bottom: 12px;
  }
  .header-text {
    font-size: 24px;
  }
  .header-3 {
    font-size: 38px;
  }
}
