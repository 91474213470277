.contact-form-section {
  position: relative;
  text-align: left;
}
.contact-form-back {
  position: absolute;
  width: 100%;
  height: Calc(100% - 280px);
  bottom: 0;
  left: 0;
}
.contact-form-back-card {
  background: var(--tertiary-background);
  padding: 16px;
  border-radius: 20px;
  width: 100%;
  display: block;
  box-sizing: border-box;
}

.form-header .header-text {
  margin-top: 4px;
}

form {
  width: 100%;
  max-width: 900px;
  margin: auto;
}
.bread-crumbs {
  margin-bottom: 8px;
  font-weight: 600;
}
.bread-crumbs a {
  color: var(--secondary-font);
}
.bread-crumbs svg {
  margin: 0 4px;
  height: 20px;
  width: 20px;
}

.form-header {
  margin-bottom: 24px;
}

.contact-first-line {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}
.contact-first-line > *:first-child {
  margin-right: 4px;
}

.contact-form-section input,
.contact-form-section .dropdown,
.contact-form-section textarea {
  position: relative;
  z-index: 1;
  border-radius: 10px;
  height: 52px;
  width: 100%;
  box-sizing: border-box;
  padding: 4px 16px;
  background: #f8fafb;
  border: 2px solid #f8fafb;
  outline: none;
  transition: 0.2s ease-in-out;
  margin: 0 0 4px;
}
.contact-form-section textarea {
  min-height: 200px;
  resize: vertical;
  font-family: inherit;
}

.contact-form-section input::placeholder,
.contact-form-section textarea::placeholder {
  color: black;
  opacity: 0.5;
}
.propjects-container-wrapper {
  margin-bottom: 8px;
}

.form-input-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form-input-wrap svg {
  /* position: absolute; */
  height: 24px;
  width: 24px;
  /* right: 12px; */
}

.subject-tipe-header {
  font-weight: 600;
  opacity: 0.3;
  margin-bottom: 8px;
}

.subject-blocks {
  animation: fadeIn ease 0.2s;
  display: flex;
  align-items: center;
  align-content: flex-start;
  flex-wrap: wrap;
}
.subject-blocks svg {
  height: 24px;
  height: 24px;
  margin: 0 0 4px;
}

.form-block {
  border-radius: 16px;
  width: 80px;
  height: 80px;
  background: var(--tertiary-color);
  border: 3px solid var(--tertiary-color);
  /* padding: 12px; */
  color: white;
  font-weight: 600;
  text-align: center;
  margin: 0 8px 8px 0;
  cursor: pointer;
  transition: 0.15s ease;
}
.form-block:hover {
  border-color: black;
}
.active-type-tile {
  border-color: black;
  color: black;
}

.form-action-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 24px;
}
.subscribe-check-wrap {
  cursor: pointer;
  margin: 0 0 16px;
}
.subscribe-check-wrap p {
  font-size: 14px;
}
.subscribe-check-wrap svg {
  width: 24px !important;
  height: 24px !important;
  padding: 8px;
  margin-left: -8px;
}

.dropdown-wrapper {
  position: relative;
  z-index: 2;
  transition: 0.2s ease;
  background: var(--background);
  border-radius: 10px;
  cursor: pointer;
}

.dropdown-wrapper svg {
  transition: 0.2s ease;
  display: block;
}
.dropdown-wrapper ul {
  padding: 8px 0 16px;
  list-style-type: none;
}
.dropdown-wrapper ul li {
  height: 36px;
  display: flex;
  align-items: center;
  /* transition: 0.15s ease; */
  padding-left: 16px;
}
.dropdown-wrapper ul li:hover {
  opacity: 1;
  background: #eaf0f3;
}

.dropdown-list {
  animation: fade-in-down ease 0.2s;
  margin-bottom: 16px;
}

.dropdown-wrapper.active {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09), 0px 10px 20px rgba(0, 0, 0, 0.12);
}
.dropdown-wrapper.active svg {
  transform: rotate(180deg);
}
.dropdown-wrapper.active ul {
  height: 100%;
}
.subject-tipe-header {
  font-size: 16px;
}

@media only screen and (min-width: 600px) {
  .contact-form-back-card {
    padding: 32px;
  }
  .form-action-bar {
    justify-content: space-between;
    flex-direction: row;
  }
  .subscribe-check-wrap {
    margin: 0 16px 0 0;
  }
  .subscribe-check-wrap p {
    font-size: inherit;
  }
  .contact-form-section input,
  .contact-form-section .dropdown,
  .contact-form-section textarea {
    border-radius: 16px;
    height: 56px;
    padding: 8px 16px;
    margin: 0 0 2px;
  }

  .contact-form-section textarea {
    margin: 0 0 16px;
  }
  .propjects-container-wrapper {
    margin-bottom: 8px;
  }

  .contact-first-line {
    flex-direction: row;
  }
  .contact-first-line > *:first-child {
    margin-right: 2px;
  }
}

@media only screen and (min-width: 900px) {
  .contact-form-back-card {
    padding: 48px;
  }
  .contact-form-section input,
  .contact-form-section .dropdown,
  .contact-form-section textarea {
    border-radius: 16px;
    padding: 8px 18px;
    margin: 0 0 8px;
  }
  .dropdown-wrapper ul {
    padding: 8px 0 18px;
  }
  .dropdown-wrapper ul li {
    height: 48px;
    padding-left: 18px;
  }
  .contact-form-section textarea {
    margin: 0 0 24px;
  }
  .propjects-container-wrapper {
    margin-bottom: 12px;
  }
  .contact-first-line > *:first-child {
    margin-right: 8px;
  }
}

@media only screen and (min-width: 1200px) {
  .contact-form-back-card {
    padding: 56px;
  }
  .contact-form-section input,
  .contact-form-section .dropdown,
  .contact-form-section textarea {
    border-radius: 16px;
    height: 64px;
    padding: 12px 24px;
    margin: 0 0 12px;
  }
  .dropdown-wrapper ul {
    padding: 8px 0 24px;
  }
  .dropdown-wrapper ul li {
    height: 48px;
    padding-left: 24px;
  }
  .contact-form-section textarea {
    margin: 0 0 24px;
  }
  .propjects-container-wrapper {
    margin-bottom: 16px;
  }
  .subject-tipe-header {
    font-size: 18px;
  }

  .contact-first-line > *:first-child {
    margin-right: 12px;
  }
}
