:root {
  --highlight: #000; /*black*/

  --primary-color: #f6e4d7; /*pink*/
  --primary-color-light: #f8f7f4; /*pink-light*/
  --secondary-color: rgba(222, 224, 223, 0.2); /*green*/
  --secondary-color-light: #b0c1a5; /*green-light*/
  --tertiary-color: #a5bfc0; /*blue*/
  --tertiary-color-light: #d2dfe0; /*blue -light*/

  --primary-font: #000;
  --secondary-font: rgba(0, 0, 0, 0.7);
  --tertiary-font: #fff;

  --primary-background: #f6e4d7;
  --secondary-background: #f8f7f4;
  --tertiary-background: #fff;
}

.gray-theme {
  --primary: #dee0df;
  --secondary: rgba(222, 224, 223, 0.2);
  --highlight: rgb(0, 0, 0);
  --background: #fff;

  /* text----- */
  --header: rgb(0, 0, 0);
  --header-inverse: #fff;
  --text: rgba(0, 0, 0, 0.7);
}

.Multicolor {
  --primary-color: rgb(0, 0, 0);
  --secondary-color: rgba(222, 224, 223, 0.2);
  --tertiary-color: rgba(222, 224, 223, 0.2);

  --primary-font: rgb(0, 0, 0);
  --secondary-font: rgba(222, 224, 223, 0.2);
  --tertiary-font: rgba(222, 224, 223, 0.2);

  --primary-background: rgb(0, 0, 0);
  --secondary-background: rgba(222, 224, 223, 0.2);
  --tertiary-background: rgba(222, 224, 223, 0.2);
}
