.product-card div {
  position: relative;
  width: Calc(100% + 8px);
  height: 0;
  border-radius: 20px;
  overflow: hidden;
  border: 8px solid rgba(0, 0, 0, 0);
  margin: -8px;
  transition: 0.2s ease;
}

.product-card div button {
  position: absolute;
  bottom: 12px;
  right: 12px;
  animation: fade-in-right ease 0.5s;
  display: none;
  z-index: 9;
}
.product-card div:hover button {
  display: flex;
}

.product-card:nth-of-type(odd) div {
  padding-bottom: 100%;
}
.product-card:nth-of-type(even) div {
  padding-bottom: 66.67%;
}

.product-card h3,
.product-card p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 8px;
}
.product-card h3 {
  margin-top: 24px;
  font-weight: 900;
}
.product-card p {
  margin-bottom: 32px;
}
.product-card div:hover {
  border: 8px solid var(--primary-color);
}
/* .product-card:nth-of-type(1n) div:hover {
  border-color: #eddfce;
}
.product-card:nth-of-type(2n) div:hover {
  border-color: #d1d6d9;
}
.product-card:nth-of-type(3n) div:hover {
  border-color: #cccfc2;
}
.product-card:nth-of-type(4n) div:hover {
  border-color: #d5c9cf;
} */

img.product-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  object-fit: cover;
  z-index: 8;
  border-radius: 8px;
}
.product-card:nth-of-type(1n) div {
  /* background: #eddfce; */
}

@media screen and (min-width: 650px) {
  .product-card h3,
  .product-card p {
    font-size: 20px;
  }
}

@media screen and (min-width: 1200px) {
  .product-card h3,
  .product-card p {
    font-size: 22px;
  }
}

@media screen and (min-width: 1700px) {
  .product-card h3,
  .product-card p {
    font-size: 24px;
  }
  .product-card h3 {
    line-height: 1.3;
  }
}
