@media screen and (min-width: 650px) {
  .product-cards-wrapper {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      "one two"
      "one three"
      "four three";
  }

  .product-cards-wrapper > *:nth-child(1) {
    grid-area: one;
  }
  .product-cards-wrapper > *:nth-child(2) {
    grid-area: two;
  }
  .product-cards-wrapper > *:nth-child(3) {
    grid-area: three;
  }
  .product-cards-wrapper > *:nth-child(4) {
    grid-area: four;
  }
}
@media screen and (min-width: 90px) {
  .product-cards-wrapper {
    grid-gap: 32px;
  }
}
@media screen and (min-width: 1200px) {
  .product-cards-wrapper {
    grid-gap: 48px;
  }
}
