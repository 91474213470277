.links-icon-wrap {
  height: 36px;
  width: 36px;
  background: var(--secondary-color-light);
  /* background: #b9cad4; */
  border-radius: 10px;
  color: white;
}

.links-icon-wrap svg {
  height: 66.66%;
  width: 66.66%;
  transition: 0.15s ease;
}
.link-item-container:hover .links-icon-wrap {
  background: rgba(176, 193, 165, 0.75);
}

.link-item-container:hover .links-icon-wrap svg {
  height: 80%;
  width: 80%;
}

.link-text-wrap {
  display: none;
}
.link-text-wrap > *:last-child,
.test-job {
  font-size: 12px;
  opacity: 0.5;
  transition: 0.15s ease;
}
.link-item-container:hover .link-text-wrap > *:last-child {
  /* color: #a5a996; */
  /* opacity: 1; */
}

@media only screen and (min-width: 700px) {
  .links-icon-wrap {
    height: 48px;
    width: 48px;
  }

  .link-text-wrap {
    display: block;
    margin-left: 16px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .link-text-wrap > *:first-child {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 2px;
  }
  .link-text-wrap > *:last-child,
  .test-job {
    font-size: 14px;
    opacity: 0.5;
  }
}

@media only screen and (min-width: 1200px) {
  .links-icon-wrap {
    height: 56px;
    width: 56px;
    border-radius: 16px;
  }

  .link-text-wrap {
    margin-left: 18px;
  }
  .link-text-wrap > *:first-child {
    font-size: 26px;
    margin-bottom: 2px;
  }
  .link-text-wrap > *:last-child,
  .test-job {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1700px) {
  .links-icon-wrap {
    height: 56px;
    width: 56px;
  }

  .link-text-wrap > *:first-child {
    font-size: 24px;
    margin-bottom: 2px;
  }
  .link-text-wrap > *:last-child,
  .test-job {
    font-size: 18px;
  }
}
