@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Font----------- */

a {
  color: inherit;
  text-decoration: none;
}

.App {
  text-align: center;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.App div {
  box-sizing: border-box;
}

ul {
  margin: 0;
  padding: 0;
}
input {
  box-shadow: inset 0px 0px 0px 0px red;
}

button {
  background: none;
  font-weight: 700;
  font-size: 16px;
  border: none;
  padding: 10px 25px;
  height: 40px;
  border-radius: 100px;
  cursor: pointer;
  outline: inherit;
  transition: 0.15s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 12px;
  gap: 12px;
  color: var(--highlight);
  white-space: nowrap;
}
button svg {
  height: 24px;
  width: 24px;
  margin-right: -8px;
}
button:hover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.09),
    0px 15px 25px rgba(0, 0, 0, 0.12);
  transform: scale(1.03);
}

button.line {
  border: 2px solid var(--highlight);
  color: var(--highlight);
  background: none;
}
button.line:hover {
  box-shadow: none;
  color: var(--tertiary-background);
  background: var(--highlight);
}
button.primary {
  background: var(--highlight);
  color: var(--tertiary-background);
}
button.secondary {
  background: white;
  color: var(--primary-font);
}

button.fog {
  /* backdrop-filter: blur(20px); */
  background: rgba(255, 255, 255, 0.8);
  color: var(--highlight);
}
button.fog:hover {
  background: white;
}

section,
footer {
  display: flex;
  justify-content: center;
  /* overflow-x: hidden; */
}

footer > *:last-child,
section > *:last-child {
  width: 100%;
  position: relative;
  z-index: 10;
  padding: 24px 16px;
  box-sizing: border-box;
}

.primary-section {
  background: var(--primary-background);
}

.secondary-section {
  background: var(--secondary-background);
}

/* common classes------------------ */
.page-section {
  padding-top: 64px;
  text-align: left;
}

/* Animations------------------ */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes drop-right {
  0% {
    transform: translatex(-16px);
  }
  100% {
    transform: translatey(0);
  }
}
@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translatey(80px);
  }
  100% {
    opacity: 1;
    transform: translatey(0);
  }
}
@keyframes fade-in-up-sml {
  0% {
    opacity: 0;
    transform: translatey(24px);
  }
  100% {
    opacity: 1;
    transform: translatey(0);
  }
}

@keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translatey(-80px);
  }
  100% {
    opacity: 1;
    transform: translatey(0);
  }
}
@keyframes fade-in-right {
  0% {
    opacity: 0;
    transform: translatex(-24px);
  }
  100% {
    opacity: 1;
    transform: translatey(0);
  }
}
@keyframes fade-in-left {
  0% {
    opacity: 0;
    transform: translatex(24px);
  }
  100% {
    opacity: 1;
    transform: translatey(0);
  }
}

.float-in-place {
  animation: float 7s ease-in-out infinite;
}
@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px) rotate(-8deg);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-8px) rotate(8deg);
  }

  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px) rotate(-8deg);
  }
}

/* Flexbox--------------------- */
.flex-just-cent {
  display: flex;
  justify-content: center;
}
.flex-just-btw {
  display: flex;
  justify-content: space-between;
}
.flex-just-rit {
  display: flex;
  justify-content: flex-end;
}
.flex-just-lft {
  display: flex;
  justify-content: flex-start;
}
.flex-align-cent {
  display: flex;
  align-items: center;
}
.flex-align-top {
  display: flex;
  align-items: flex-start;
}
.flex-col {
  display: flex;
  flex-direction: column;
}

/* mobile------------------------------- */
.desktop-only {
  display: none;
}

.mobile-only {
  display: flex;
}

/* Tablet---- */
@media only screen and (min-width: 600px) {
  footer > *:last-child,
  section > *:last-child {
    max-width: 800px;
    padding: 36px 16px;
  }

  /* mobile------------------------------- */
  .desktop-only {
    display: flex;
  }

  .mobile-only {
    display: none;
  }
}
/* Desktop---- */
@media only screen and (min-width: 900px) {
  footer > *:last-child,
  section > *:last-child {
    max-width: 1088px;
    padding: 64px 36px;
  }
  p {
    font-size: 18px;
    line-height: 30px;
  }
}
@media only screen and (min-width: 1200px) {
  footer > *:last-child,
  section > *:last-child {
    max-width: 1272px;
    padding: 36px 16px;
  }
  button {
    height: 48px;
  }
  button.header-button {
    height: 40px;
  }
}

:root {
  --highlight: #000; /*black*/

  --primary-color: #f6e4d7; /*pink*/
  --primary-color-light: #f8f7f4; /*pink-light*/
  --secondary-color: rgba(222, 224, 223, 0.2); /*green*/
  --secondary-color-light: #b0c1a5; /*green-light*/
  --tertiary-color: #a5bfc0; /*blue*/
  --tertiary-color-light: #d2dfe0; /*blue -light*/

  --primary-font: #000;
  --secondary-font: rgba(0, 0, 0, 0.7);
  --tertiary-font: #fff;

  --primary-background: #f6e4d7;
  --secondary-background: #f8f7f4;
  --tertiary-background: #fff;
}

.gray-theme {
  --primary: #dee0df;
  --secondary: rgba(222, 224, 223, 0.2);
  --highlight: rgb(0, 0, 0);
  --background: #fff;

  /* text----- */
  --header: rgb(0, 0, 0);
  --header-inverse: #fff;
  --text: rgba(0, 0, 0, 0.7);
}

.Multicolor {
  --primary-color: rgb(0, 0, 0);
  --secondary-color: rgba(222, 224, 223, 0.2);
  --tertiary-color: rgba(222, 224, 223, 0.2);

  --primary-font: rgb(0, 0, 0);
  --secondary-font: rgba(222, 224, 223, 0.2);
  --tertiary-font: rgba(222, 224, 223, 0.2);

  --primary-background: rgb(0, 0, 0);
  --secondary-background: rgba(222, 224, 223, 0.2);
  --tertiary-background: rgba(222, 224, 223, 0.2);
}

/* @import url("https://rsms.me/inter/inter.css");
html {
  font-family: "Inter", sans-serif;
} */
html,
body {
  font-family: "Roboto", sans-serif;
}

h1 {
  font-weight: 900;
  font-size: 38px;
  line-height: 1.05;
  margin: 0;
}
h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.45;
  margin: 0;
}
h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 1.3;
  margin: 0;
}
h4,
.reviews-profile {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.45;
  margin: 0;
}
h5 {
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 1.5;
  margin: 0;
}
h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  margin: 0;
}
input,
p,
textarea,
button,
.nav-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.6;
  margin: 0;
}

a {
  font-weight: 400;
}
button,
.nav-text {
  font-weight: 600 !important;
}
.header-2 {
  cursor: default;
  font-size: 32px;
  margin-bottom: 4px;
  font-weight: 800;
  line-height: 1.3;
  text-transform: capitalize;
}
.header-text {
  font-size: 18px;
  color: var(--secondary-font);
}
.header-3 {
  font-style: normal;
  font-size: 20px;
  line-height: 1.45;
  margin: 0;
  font-weight: 700;
}

@media screen and (min-width: 650px) {
  h1 {
    font-size: 56px;
  }
  h2,
  h4,
  .reviews-profile {
    font-size: 24px;
  }
  h3 {
    font-size: 40px;
  }
  h5,
  h6 {
    font-size: 20px;
  }
  input,
  p,
  textarea,
  button,
  .nav-text {
    font-size: 18px;
  }
  .header-2 {
    font-size: 40px;
    margin-bottom: 4px;
  }
  .header-text {
    font-size: 20px;
  }
  .header-3 {
    font-size: 24px;
  }
}
@media screen and (min-width: 1200px) {
  h1 {
    font-size: 64px;
  }
  h2,
  h4,
  .reviews-profile {
    font-size: 26px;
  }
  h3 {
    font-size: 48px;
  }

  h5,
  h6 {
    font-size: 22px;
  }
  input,
  textarea,
  p {
    font-size: 20px;
  }
  .header-2 {
    font-size: 48px;
    margin-bottom: 4px;
  }
  .header-text {
  }
  .header-3 {
    font-size: 32px;
  }
}

@media screen and (min-width: 1700px) {
  h1 {
    font-size: 80px;
  }
  h2,
  h4,
  .reviews-profile {
    font-size: 32px;
  }
  h3 {
    font-size: 56px;
  }

  h5,
  h6 {
    font-size: 24px;
  }
  input,
  textarea,
  p {
    font-size: 22px;
  }

  button,
  .nav-text {
    font-size: 20px;
  }
  .header-2 {
    font-size: 56px;
    margin-bottom: 12px;
  }
  .header-text {
    font-size: 24px;
  }
  .header-3 {
    font-size: 38px;
  }
}

header {
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  z-index: 101;
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  transform: translateY(0);
  transition: transform 0.2s ease-in-out, background 0.3s ease,
    box-shadow 0.4s ease-in-out;
  background: var(--tertiary-background);
}

header.header-shadow {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09), 0px 10px 20px rgba(0, 0, 0, 0.12);
}
header.header-hidden {
  transform: translateY(-110%);
}
header.mobile-menue-active {
  background: var(--tertiary-background);
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.14);
}

header > * {
  width: 100%;
  padding: 0 16px;
  min-height: 56px;
}

header ul {
  list-style-type: none;
}

header li {
  padding: 8px 24px;
  text-decoration: none;
  font-weight: 600;
  transition: 0.15s ease;
}
header li:hover {
  opacity: 0.5;
}

.header-logo svg {
  height: 36px;
}
.header-logo svg.logo-text {
  height: 18px;
  margin-left: 12px;
}
.logo-no-text,
.extra-button,
.primary-button,
.header-nav-wrap {
  position: relative;
  display: none;
}
.logo-text {
  display: block;
}

#mobile-menu-button svg {
  width: 32px;
  height: 32px;
}

.mobile-menu-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: none;
  animation: fadeIn ease 0.2s;
}
.mobile-menu-wrap.mobile-menue-active {
  display: block;
}

.mobile-menu {
  display: none;
  box-sizing: border-box;
  width: 100%;
  padding: 8px 8px 16px;
  animation: fade-in-down ease 0.2s;
}
header.mobile-menue-active .mobile-menu {
  display: block;
}
.mobile-menu ul {
  margin: 0 !important;
  padding: 4px 0 !important;
  border-top: 1px solid rgba(185, 202, 212, 0.5);
  border-bottom: 1px solid rgba(185, 202, 212, 0.5);
}
.mobile-menu ul li {
  width: 100%;
  padding: 16px 30px;
  box-sizing: border-box;
  text-align: left;
}
.mobile-menu ul li a {
  text-decoration: none;
  color: var(--secondary-font-color);
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  transition: 0.15s ease;
}

.mobile-menu ul li button {
  width: 100%;
  font-size: 18px;
  height: 48px;
}

.header-foot {
  padding: 16px 0 0 !important;
  /* background: var(--highlight); */
}
.header-foot .footer-social-links > *:not(:last-child) {
  margin-right: 16px !important;
}
.header-foot a {
  padding: 8px !important;
  background: #b9cad4 !important;
  border-radius: 10px !important;
}
.header-foot svg {
  height: 28px !important;
  width: 28px !important;
}

.header-tem {
  width: 100%;
}
@media only screen and (min-width: 600px) {
  .header-nav-wrap {
    position: relative;
  }
  .header-button-wrap > * {
    margin-left: 24px;
  }
}
@media only screen and (min-width: 700px) {
  header {
    flex-direction: row;
    justify-content: center;
  }
  .header-tem {
    width: 30%;
  }
  .mobile-menu {
    display: none;
  }
  .header-nav-wrap,
  .primary-button {
    display: flex;
  }

  #mobile-menu-button {
    display: none;
  }
  .logo-text {
    display: none;
  }
  .logo-no-text {
    display: block;
  }
  .header-nav-underline {
    position: absolute;
    bottom: 0;
    left: 0;
    transition: 0.2s ease-in-out;
    margin-bottom: -28px;
  }
  .header-nav-underline.active-tab {
    /* margin-bottom: -18px; */
    margin-bottom: 0;
  }

  .header-nav-select {
    /* width: 8px;
    height: 8px;
    background: var(--tertiary-color);
    border: 2px solid var(--tertiary-color);
    border-radius: 4px; */

    width: 100%;
    height: 6px;
    background: var(--tertiary-color-light);
    border-radius: 4px;

    /* width: Calc(100% + 20px);
    height: 6px;
    margin: 0 -10px;
    background: var(--tertiary-color);
    border-radius: 4px 4px 0 0; */
  }
}

@media only screen and (min-width: 900px) {
  header > * {
    min-height: 80px;
  }
  .header-logo svg {
    height: 48px;
  }
  .header-logo svg.logo-text {
    height: 24px;
    margin-left: 16px;
  }
  .logo-text {
    display: block;
  }
  .logo-no-text {
    display: none;
  }
  .header-nav-underline.active-tab {
    margin-bottom: -2px;
  }
}

@media only screen and (min-width: 1200px) {
  header > * {
    padding: 0 32px;
  }
  .extra-button {
    display: flex;
  }
}

.landing-section {
  height: min(100vh, 200vw);
  /* min-height: Calc(100vw * 0.67); */
  box-sizing: border-box;
  padding-top: 56px;
  max-height: 1272px;
  position: relative;
}

.landing-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  width: 100%;
}
.landing-wrapper > * {
  position: absolute;
  bottom: 24px;
  width: Calc(100% - 32px);
  height: 50%;
  text-align: left;
}

.landing-img {
  position: absolute;
  top: 56px;
  z-index: 1;
  width: 110%;
  /* height: 100vw; */
  height: 50%;
  height: 52%;
  object-fit: cover;
}

.landing-button-wrap {
  flex-wrap: wrap;
  margin-top: 32px;
  grid-row-gap: 8px;
  row-gap: 8px;
}

.landing-button-wrap button {
  height: 42px;
}
.landing-button-wrap > *:first-child {
  margin-right: 8px;
}

.landing-script {
  width: 120%;
  margin: -12% 0 -25% -7%;
}

#landing-button-arrow {
  display: none;
}

@media only screen and (min-width: 380px) {
  #landing-button-arrow {
    display: block;
  }
}

@media only screen and (min-width: 700px) {
  .landing-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
  }
  .landing-wrapper > * {
    position: relative;
    bottom: 0;

    width: 50%;
    height: auto;
    text-align: left;
  }

  .landing-img {
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
  }
  .landing-button-wrap {
    grid-row-gap: 12px;
    row-gap: 12px;
  }

  .landing-button-wrap button {
    height: 42px;
  }
  .landing-button-wrap > *:first-child {
    margin-right: 16px;
  }

  .landing-script {
    width: 150%;
    margin: 0 0 -30% -16%;
  }
}

@media only screen and (min-width: 900px) {
  .landing-section {
    padding-top: 80px;
  }
  .landing-button-wrap button {
    height: 56px;
    padding: 10px 32px;
    font-size: 18px;
  }
  .landing-button-wrap > *:first-child {
    margin-right: 24px;
  }
}

@media only screen and (min-width: 1200px) {
}

.links-cont {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 15;

  background: var(--tertiary-background);
  box-sizing: border-box;

  padding: 18px 32px;
  border-radius: 40px 0 0 40px;
  margin-bottom: -36px;
  overflow: hidden;
}

.links-cont-headr {
  text-align: left;
  margin-bottom: 8px;
  font-weight: 700;
  opacity: 0.5;
  display: none;
}

.links-wrap > *:not(:last-child) {
  margin-right: 16px;
}

.links-wrap > * {
  opacity: 0;
  transform: translatex(24px) translatey(16px);
  transition: 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}
.links-wrap > *:nth-child(1) {
  transition-delay: 0s;
}
.links-wrap > *:nth-child(2) {
  transition-delay: 0.2s;
}
.links-wrap > *:nth-child(3) {
  transition-delay: 0.3s;
}
.links-wrap > *:nth-child(4) {
  transition-delay: 0.4s;
}

.links-wrap.show-icons > * {
  opacity: 1;
  transform: translatey(0);
}

@media only screen and (min-width: 600px) {
  .links-cont {
    border-radius: 36px 0 0 36px;
    padding: 24px 36px;
    margin-bottom: -44px;
  }
  .links-wrap > *:not(:last-child) {
    margin-right: 24px;
  }
  .links-cont-headr {
    margin-bottom: 16px;
  }
}

@media only screen and (min-width: 900px) {
  .links-cont {
    border-radius: 36px 0 0 36px;
    padding: 24px 36px;
    margin-bottom: -48px;
  }
  .links-wrap > *:not(:last-child) {
    margin-right: 36px;
  }
  .links-cont-headr {
    margin-bottom: 16px;
  }
}

@media only screen and (min-width: 1200px) {
  .links-cont {
    border-radius: 36px 0 0 36px;
    padding: 24px 48px;
    margin-bottom: -52px;
  }
  .links-wrap > *:not(:last-child) {
    margin-right: 36px;
  }
  .links-cont-headr {
    margin-bottom: 16px;
  }
}

@media only screen and (min-width: 1700px) {
  .links-cont {
    border-radius: 40px 0 0 40px;
    padding: 24px 48px;
  }
  .links-wrap > *:not(:last-child) {
    margin-right: 36px;
  }
  .links-cont-headr {
    margin-bottom: 48px;
  }
}

.links-icon-wrap {
  height: 36px;
  width: 36px;
  background: var(--secondary-color-light);
  /* background: #b9cad4; */
  border-radius: 10px;
  color: white;
}

.links-icon-wrap svg {
  height: 66.66%;
  width: 66.66%;
  transition: 0.15s ease;
}
.link-item-container:hover .links-icon-wrap {
  background: rgba(176, 193, 165, 0.75);
}

.link-item-container:hover .links-icon-wrap svg {
  height: 80%;
  width: 80%;
}

.link-text-wrap {
  display: none;
}
.link-text-wrap > *:last-child,
.test-job {
  font-size: 12px;
  opacity: 0.5;
  transition: 0.15s ease;
}
.link-item-container:hover .link-text-wrap > *:last-child {
  /* color: #a5a996; */
  /* opacity: 1; */
}

@media only screen and (min-width: 700px) {
  .links-icon-wrap {
    height: 48px;
    width: 48px;
  }

  .link-text-wrap {
    display: block;
    margin-left: 16px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .link-text-wrap > *:first-child {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 2px;
  }
  .link-text-wrap > *:last-child,
  .test-job {
    font-size: 14px;
    opacity: 0.5;
  }
}

@media only screen and (min-width: 1200px) {
  .links-icon-wrap {
    height: 56px;
    width: 56px;
    border-radius: 16px;
  }

  .link-text-wrap {
    margin-left: 18px;
  }
  .link-text-wrap > *:first-child {
    font-size: 26px;
    margin-bottom: 2px;
  }
  .link-text-wrap > *:last-child,
  .test-job {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1700px) {
  .links-icon-wrap {
    height: 56px;
    width: 56px;
  }

  .link-text-wrap > *:first-child {
    font-size: 24px;
    margin-bottom: 2px;
  }
  .link-text-wrap > *:last-child,
  .test-job {
    font-size: 18px;
  }
}


.service-cards-cont {
  position: relative;
  padding: 0 0 72px 16px;
  overflow: hidden;
  width: Calc(100% + 32px);
  margin: 0 0 -24px -16px;
  overflow-x: auto;
}
.service-cards-wrap {
  grid-gap: 24px;
  min-width: 994px;
  display: flex;
  flex-direction: row;
}

.horizontalSlider___281Ls {
  overflow: visible !important;
}
#services .carousel {
  padding: 0 8px;
  margin: -24px 0 0;
}
#services .horizontalSlider___281Ls li {
  margin-right: 24px;
  width: Calc(14.27% - 24px) !important;
  padding-bottom: min(20%, 300px) !important;
  /* transition: 0.3s cubic-bezier(0.34, 1.56, 0.64, 1); */
}

#services .carousel ul > .carousel__slide--visible a {
  box-shadow: 0px 20px 40px rgb(0 0 0 / 14%);
  transform: scale(1.05);
}
#services .carousel ul > .carousel__slide--visible button {
  background: var(--highlight);
  color: var(--tertiary-background);
}
#services .carousel ul > .carousel__slide--visible .services-icon-wrap {
  background: var(--tertiary-color);
}
#services
  .carousel
  ul
  > .carousel__slide--visible
  ~ .carousel__slide--visible
  a {
  box-shadow: none;
  transform: scale(1);
}
#services
  .carousel
  ul
  > .carousel__slide--visible
  ~ .carousel__slide--visible
  button {
  background: none;
  color: var(--highlight);
}
#services
  .carousel
  ul
  > .carousel__slide--visible
  ~ .carousel__slide--visible
  .services-icon-wrap {
  background: var(--tertiary-color-light);
  /* color: var(--highlight); */
}
#services .testimonial-card {
  box-shadow: none;
}

#services .sliderAnimation___300FY > * {
  opacity: 0;
  transform: translatey(80px);
  transition: 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
  transition-delay: 0.45s;
}

#services .sliderAnimation___300FY > *:nth-child(1) {
  transition-delay: 0s;
}
#services .sliderAnimation___300FY > *:nth-child(2) {
  transition-delay: 0.2s;
}
#services .sliderAnimation___300FY > *:nth-child(3) {
  transition-delay: 0.3s;
}
#services .sliderAnimation___300FY > *:nth-child(4) {
  transition-delay: 0.4s;
}

#services .active-test .sliderAnimation___300FY > * {
  opacity: 1;
  transform: translatey(0);
}
.test-cards-wrap {
  margin-top: -36px;
}

a.service-card-more-link {
  background: none !important;
  overflow: visible !important;
  box-shadow: none !important;
}
button.mobile-services-button {
  /* height: 48px;
  width: 48px; */
  /* padding: 0; */
  box-shadow: none !important;
  background: none !important;
  transform: scale(1) !important;
  /* color: #b9cad4; */
  color: black;
  opacity: 0.15;
}
button.mobile-services-button:hover {
  opacity: 1 !important;
}

/* button.mobile-services-button span {
  opacity: 1;
  text-transform: none;
  display: block;
  padding-right: 42px;
  font-size: 16px;
  height: 48px;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
} */
button.mobile-services-button svg {
  margin: 0 !important;
  height: 24px !important;
  width: 24px !important;
  /* position: absolute;
  animation: drop-right ease-in 0.8s alternate infinite; */
}
.hint-wrapper-services {
  width: 96%;
  margin-bottom: -16px;
  padding-top: 16px;
}
@media only screen and (min-width: 600px) {
  #services {
    padding-bottom: 24px;
  }
  #services .horizontalSlider___281Ls li {
    margin-right: 24px;
    padding-bottom: min(20%, 360px) !important;
  }
  #services .carousel {
    padding: 0 0 0 16;
    margin: -16px 0 0;
  }
  .hint-wrapper-services {
    margin: 24px 0 -24px;
    width: 48%;
  }
}

@media only screen and (min-width: 900px) {
  #services {
    /* padding-bottom: 32px; */
  }
  #services .carousel {
    padding: 0 0 0 24px;
    margin: 0;
  }
  .hint-wrapper-services {
    margin: 24px 0 -48px;
    width: 35%;
  }
}
@media only screen and (min-width: 1200px) {
  #services {
    padding-bottom: 48px;
  }
}
@media only screen and (min-width: 1700px) {
  #services {
    padding-bottom: 48px;
  }
  #services .carousel {
    padding: 0 0 0 0;
    margin: 0;
  }
  .hint-wrapper-services {
    margin: 24px 0 -48px -24px;
    width: 35%;
  }
}

a.service-card-wrapper {
  background: var(--background);
  border-radius: 30px;
  padding: 32px;
  width: 100%;
  height: 100%;
  display: block;
  box-sizing: border-box;
  transition: 0.2s ease;
  box-sizing: border-box;
  overflow: hidden;
  background: var(--tertiary-background);
}
.active-service-card button,
.active-service-card .services-icon-wrap {
  transition: 0.2s ease;
}

.active-service-card {
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.08);
  transform: scale(1.05);
}

.active-service-card .services-icon-wrap {
  background: #b9cad4;
}

.active-service-card button {
  background: var(--highlight);
  color: var(--tertiary-background);
}

.services-icon-wrap {
  width: 56px;
  height: 56px;
  border-radius: 16px;
  background: var(--tertiary-color-light);
  color: white;
}
.services-icon-wrap svg {
  height: 36px;
}
.service-card-wrapper h4 {
  margin: 24px 0 4px;
}
.service-card-wrapper p {
  margin: 0 0 24px;
}
@media only screen and (min-width: 600px) {
  a.service-card-wrapper {
    /* width: 320px;
    min-width: 320px;
    min-height: 320px; */
    border-radius: 25px;
  }
}
@media only screen and (min-width: 900px) {
  a.service-card-wrapper {
    /* width: 320px;
    min-height: 320px; */
    border-radius: 25px;
  }
  .services-icon-wrap {
    width: 64px;
    height: 64px;
    border-radius: 16px;
  }
  .services-icon-wrap svg {
    height: 48px;
  }
}
@media only screen and (min-width: 1200px) {
  a.service-card-wrapper {
    /* width: 370px; */
    /* height: 370px;
    padding: 48px; */
    border-radius: 30px;
  }
}
@media only screen and (min-width: 1700px) {
  a.service-card-wrapper {
    padding: 48px;
    /* width: 390px;
    height: 390px; */
  }
}

.page-section-header {
  margin-bottom: 48px;
  flex-direction: column;
}

.page-section span {
  text-transform: uppercase;
  font-size: 18px;
  opacity: 0.5;
  display: block;
  margin-bottom: 12px;
}

.page-section-header h3,
.page-section-header p {
  width: 100%;
  margin-bottom: 12px;
}
.page-section-header h3 {
  margin-top: -4px;
}
.page-section-header .header-2 {
  /* max-width: 500px; */
}
@media only screen and (min-width: 650px) {
  .page-section-header {
    margin-bottom: 64px;
    flex-direction: row;
  }
  .page-section-header h3,
  .page-section-header p {
    max-width: 500px;
    margin-bottom: 0;
  }
  .page-section-header h3 {
    max-width: none;
  }
}

#products {
  background: var(--tertiary-background);
}
.products-more-button {
  margin: 24px auto;
}

@media only screen and (min-width: 900px) {
  .products-more-button {
    height: 56px;
  }
}

@media screen and (min-width: 650px) {
  .product-cards-wrapper {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      "one two"
      "one three"
      "four three";
  }

  .product-cards-wrapper > *:nth-child(1) {
    grid-area: one;
  }
  .product-cards-wrapper > *:nth-child(2) {
    grid-area: two;
  }
  .product-cards-wrapper > *:nth-child(3) {
    grid-area: three;
  }
  .product-cards-wrapper > *:nth-child(4) {
    grid-area: four;
  }
}
@media screen and (min-width: 90px) {
  .product-cards-wrapper {
    grid-gap: 32px;
  }
}
@media screen and (min-width: 1200px) {
  .product-cards-wrapper {
    grid-gap: 48px;
  }
}

.product-card div {
  position: relative;
  width: Calc(100% + 8px);
  height: 0;
  border-radius: 20px;
  overflow: hidden;
  border: 8px solid rgba(0, 0, 0, 0);
  margin: -8px;
  transition: 0.2s ease;
}

.product-card div button {
  position: absolute;
  bottom: 12px;
  right: 12px;
  animation: fade-in-right ease 0.5s;
  display: none;
  z-index: 9;
}
.product-card div:hover button {
  display: flex;
}

.product-card:nth-of-type(odd) div {
  padding-bottom: 100%;
}
.product-card:nth-of-type(even) div {
  padding-bottom: 66.67%;
}

.product-card h3,
.product-card p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 8px;
}
.product-card h3 {
  margin-top: 24px;
  font-weight: 900;
}
.product-card p {
  margin-bottom: 32px;
}
.product-card div:hover {
  border: 8px solid var(--primary-color);
}
/* .product-card:nth-of-type(1n) div:hover {
  border-color: #eddfce;
}
.product-card:nth-of-type(2n) div:hover {
  border-color: #d1d6d9;
}
.product-card:nth-of-type(3n) div:hover {
  border-color: #cccfc2;
}
.product-card:nth-of-type(4n) div:hover {
  border-color: #d5c9cf;
} */

img.product-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  object-fit: cover;
  z-index: 8;
  border-radius: 8px;
}
.product-card:nth-of-type(1n) div {
  /* background: #eddfce; */
}

@media screen and (min-width: 650px) {
  .product-card h3,
  .product-card p {
    font-size: 20px;
  }
}

@media screen and (min-width: 1200px) {
  .product-card h3,
  .product-card p {
    font-size: 22px;
  }
}

@media screen and (min-width: 1700px) {
  .product-card h3,
  .product-card p {
    font-size: 24px;
  }
  .product-card h3 {
    line-height: 1.3;
  }
}

.test-button-wrap {
  height: 100%;
  width: 100%;
  /* display: block; */
  box-sizing: border-box;
  /* border: 1px solid red; */
  display: none;
}
.test-button-wrap button {
  padding: 0;
}
.test-button-wrap button {
  margin-left: 16px;
}
.test-button-wrap button svg {
  height: 24px;
  width: 24px;
  margin: 0;
}

.test-cards-wrap > *:not(:last-child) {
  margin-right: 24px;
}

.horizontalSlider___281Ls {
  overflow: visible;
}
#testimonials .horizontalSlider___281Ls li {
  margin-right: 24px;
  width: Calc(16.66% - 24px) !important;
  padding-bottom: min(20%, 300px) !important;
}

.car-button {
  display: none;
}
.testimonial-card-end {
  text-align: center;
  transition: 0.2s ease;
  cursor: pointer;
}
.testimonial-card-end:hover {
  transform: scale(1.02);
}
.testimonial-card-end button:hover {
  transform: scale(1) !important;
}

#testimonials .sliderAnimation___300FY > * {
  opacity: 0;
  transform: translatey(80px);
  transition: 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
  transition-delay: 0.45s;
}
#testimonials .sliderAnimation___300FY > *:nth-child(1) {
  transition-delay: 0s;
}
#testimonials .sliderAnimation___300FY > *:nth-child(2) {
  transition-delay: 0.2s;
}
#testimonials .sliderAnimation___300FY > *:nth-child(3) {
  transition-delay: 0.3s;
}
#testimonials .sliderAnimation___300FY > *:nth-child(4) {
  transition-delay: 0.4s;
}

#testimonials.active-test .sliderAnimation___300FY > * {
  opacity: 1;
  transform: translatey(0);
}
.test-cards-wrap {
  margin-top: -36px;
}

@media screen and (min-width: 650px) {
  .test-button-wrap {
    display: flex;
  }
  .test-button-wrap button {
    height: 48px;
    width: 48px;
  }
  #testimonials .horizontalSlider___281Ls li {
    padding-bottom: min(25%, 370px) !important;
  }
  .test-cards-wrap {
    margin-top: -24px;
  }
}
@media screen and (min-width: 900px) {
  .test-cards-wrap {
    margin-top: 0;
  }
}
@media screen and (min-width: 1080px) {
  #testimonials .horizontalSlider___281Ls li {
    padding-bottom: 20% !important;
  }
}
@media screen and (min-width: 1200px) {
  .test-button-wrap button {
    height: 56px;
    width: 56px;
    width: 56px;
  }
  .test-button-wrap button svg {
    height: 32px;
    width: 32px;
  }
  #testimonials .horizontalSlider___281Ls li {
    padding-bottom: min(20%, 330px) !important;
  }
  #testimonials {
    padding-bottom: 36px;
  }
}

@media screen and (min-width: 1700px) {
  .test-button-wrap button {
    height: 64px;
    width: 64px;
  }
  .test-button-wrap button {
    margin-left: 24px;
  }
  .test-button-wrap button svg {
    height: 48px;
    width: 48px;
  }
  #testimonials .horizontalSlider___281Ls li {
    padding-bottom: min(20%, 400px) !important;
  }
}

.testimonial-card {
  padding: 24px;
  background: var(--background);
  border-radius: 20px;
  height: 100%;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.14);
  overflow: hidden;
  background: var(--tertiary-background);
}
.testimonial-quote {
  height: 24px;
  color: ;
}

.reviews-profile {
  background: var(--tertiary-color);
  width: 32px;
  height: 32px;
  border-radius: 100%;
  font-weight: 800;
  color: var(--tertiary-background);
}

.star {
  height: 24px;
  width: 24px;
}
.reviews-profile {
  margin: 16px 16px 24px 0;
}

@media screen and (min-width: 650px) {
  .testimonial-quote {
    height: 32px;
  }
  .reviews-profile {
    height: 48px;
    width: 48px;
  }
}

@media screen and (min-width: 1200px) {
  .testimonial-quote {
    height: 48px;
  }
  .reviews-profile {
    height: 56px;
    width: 56px;
  }
}

@media screen and (min-width: 1700px) {
  .testimonial-quote {
    height: 56px;
  }
  .reviews-profile {
    height: 64px;
    width: 64px;
  }
}

.contact-section-wrap {
  text-align: center;
}

.contact-section-wrap .section-header {
  margin: auto;
  max-width: 600px;
}
#contact .header-2 {
  margin-bottom: 12px;
}

.contact-input-wrap {
  transition: 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
  border-radius: 100px;
  box-sizing: border-box;
  margin: 48px 0;
  padding: 12px 12px 12px 32px;
  width: 756px;
  cursor: pointer;
  transform: scale(0.9);
  background: var(--tertiary-background);
}

#contact .active-test .contact-input-wrap {
  transform: scale(1);
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.14);
}
.contact-input-wrap:hover,
.active-input {
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2) !important;
}

.contact-input-wrap button {
  min-height: 48px;
}
.contact-input-wrap input {
  outline: none;
  border: none;
  background: none;
  margin-right: 8px;
  width: 100%;
  cursor: pointer;
}

.contact-input-wrap input::-webkit-input-placeholder {
}

.contact-input-wrap input:-ms-input-placeholder {
}

.contact-input-wrap input::placeholder {
}

@media screen and (min-width: 650px) {
  .contact-input-wrap {
    padding: 16px 16px 16px 32px;
  }
  .contact-input-wrap button {
    min-height: 56px;
  }
}

#footer {
  background: var(--tertiary-background);
}
.footer-brand {
  max-width: 280px;
}
.footer-content > * {
  flex-direction: column;
  align-items: center;
}
.footer-content > * div {
  width: 100%;
  align-items: center;
}

.footer-brand {
  margin-bottom: 24px;
}

.footer-brand svg {
  height: 48px;
  width: 48px;
  margin-bottom: 16px;
}

.footer-content-section p {
  display: flex;
  align-items: center;
  height: 32px;
  margin-bottom: 24px;
}
.footer-content-section a {
  height: 42px;
  transition: 0.15s ease;
}
.footer-content-section a:hover {
  color: var(--primary-font);
}

footer {
  background: black;
  padding-top: 0 !important;
  color: white;
  overflow: hidden;
}
footer > * {
  grid-gap: 16px;
  gap: 16px;
  flex-direction: column-reverse;
  text-align: center;
}
.footer-social-piller,
.footer-piller,
.footer-com {
  display: none;
}

.footer-social-links > *:not(:last-child) {
  margin-right: 8px;
}

.footer-social-links a {
  transition: 0.15s;
  background: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 6px;
  display: block;
  box-sizing: border-box;
}
.footer-social-links a:hover {
  background: rgba(255, 255, 255, 0.1);
}
.footer-social-links a svg {
  display: block;
  width: 24px;
  color: white;
  height: 24px;
}
footer > * div:first-of-type {
  font-size: 14px;
}

@media only screen and (min-width: 600px) {
  .footer-content > * {
    grid-gap: 32px;
    gap: 32px;
    flex-direction: row;
    align-items: flex-start;
    padding-bottom: 24px;
  }
  .footer-content > * div {
    text-align: left;
    align-items: flex-start;
  }

  .footer-brand {
    margin-bottom: 0;
  }
  .footer-brand svg {
    margin-bottom: 24px;
  }
  .footer-content-sections-wrapper {
    grid-gap: 48px;
    gap: 48px;
    width: 100%;
    max-width: 400px;
  }
  .footer-content-section p {
    height: 48px;
    margin-bottom: 32px;
  }
  .footer-piller {
    display: flex;
  }
  .footer-com {
    display: block;
  }
  footer {
    height: 48px;
  }
  footer > * {
    grid-gap: 0;
    gap: 0;
    flex-direction: row;
    text-align: left;
  }
}

@media only screen and (min-width: 900px) {
  .footer-social-piller {
    display: flex;
  }
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  left: 0;
  z-index: 200;
  transition: 0.15s ease;
  background: rgba(255, 255, 255, 0.3);
  animation: fade-in 0.2s ease forwards;
}

.modal-wrapper .testimonial-card {
  position: relative;
  z-index: 220;
  max-height: 300px;
  max-width: 400px;
  margin: 24px;
  animation: fade-in-up 0.4s ease forwards;
}

.sent-animation {
  animation: fade-out 2s ease-in-out forwards;
  animation-delay: 6s;
}

.contact-form-section {
  position: relative;
  text-align: left;
}
.contact-form-back {
  position: absolute;
  width: 100%;
  height: Calc(100% - 280px);
  bottom: 0;
  left: 0;
}
.contact-form-back-card {
  background: var(--tertiary-background);
  padding: 16px;
  border-radius: 20px;
  width: 100%;
  display: block;
  box-sizing: border-box;
}

.form-header .header-text {
  margin-top: 4px;
}

form {
  width: 100%;
  max-width: 900px;
  margin: auto;
}
.bread-crumbs {
  margin-bottom: 8px;
  font-weight: 600;
}
.bread-crumbs a {
  color: var(--secondary-font);
}
.bread-crumbs svg {
  margin: 0 4px;
  height: 20px;
  width: 20px;
}

.form-header {
  margin-bottom: 24px;
}

.contact-first-line {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}
.contact-first-line > *:first-child {
  margin-right: 4px;
}

.contact-form-section input,
.contact-form-section .dropdown,
.contact-form-section textarea {
  position: relative;
  z-index: 1;
  border-radius: 10px;
  height: 52px;
  width: 100%;
  box-sizing: border-box;
  padding: 4px 16px;
  background: #f8fafb;
  border: 2px solid #f8fafb;
  outline: none;
  transition: 0.2s ease-in-out;
  margin: 0 0 4px;
}
.contact-form-section textarea {
  min-height: 200px;
  resize: vertical;
  font-family: inherit;
}

.contact-form-section input::-webkit-input-placeholder, .contact-form-section textarea::-webkit-input-placeholder {
  color: black;
  opacity: 0.5;
}

.contact-form-section input:-ms-input-placeholder, .contact-form-section textarea:-ms-input-placeholder {
  color: black;
  opacity: 0.5;
}

.contact-form-section input::placeholder,
.contact-form-section textarea::placeholder {
  color: black;
  opacity: 0.5;
}
.propjects-container-wrapper {
  margin-bottom: 8px;
}

.form-input-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form-input-wrap svg {
  /* position: absolute; */
  height: 24px;
  width: 24px;
  /* right: 12px; */
}

.subject-tipe-header {
  font-weight: 600;
  opacity: 0.3;
  margin-bottom: 8px;
}

.subject-blocks {
  animation: fadeIn ease 0.2s;
  display: flex;
  align-items: center;
  align-content: flex-start;
  flex-wrap: wrap;
}
.subject-blocks svg {
  height: 24px;
  height: 24px;
  margin: 0 0 4px;
}

.form-block {
  border-radius: 16px;
  width: 80px;
  height: 80px;
  background: var(--tertiary-color);
  border: 3px solid var(--tertiary-color);
  /* padding: 12px; */
  color: white;
  font-weight: 600;
  text-align: center;
  margin: 0 8px 8px 0;
  cursor: pointer;
  transition: 0.15s ease;
}
.form-block:hover {
  border-color: black;
}
.active-type-tile {
  border-color: black;
  color: black;
}

.form-action-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 24px;
}
.subscribe-check-wrap {
  cursor: pointer;
  margin: 0 0 16px;
}
.subscribe-check-wrap p {
  font-size: 14px;
}
.subscribe-check-wrap svg {
  width: 24px !important;
  height: 24px !important;
  padding: 8px;
  margin-left: -8px;
}

.dropdown-wrapper {
  position: relative;
  z-index: 2;
  transition: 0.2s ease;
  background: var(--background);
  border-radius: 10px;
  cursor: pointer;
}

.dropdown-wrapper svg {
  transition: 0.2s ease;
  display: block;
}
.dropdown-wrapper ul {
  padding: 8px 0 16px;
  list-style-type: none;
}
.dropdown-wrapper ul li {
  height: 36px;
  display: flex;
  align-items: center;
  /* transition: 0.15s ease; */
  padding-left: 16px;
}
.dropdown-wrapper ul li:hover {
  opacity: 1;
  background: #eaf0f3;
}

.dropdown-list {
  animation: fade-in-down ease 0.2s;
  margin-bottom: 16px;
}

.dropdown-wrapper.active {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09), 0px 10px 20px rgba(0, 0, 0, 0.12);
}
.dropdown-wrapper.active svg {
  transform: rotate(180deg);
}
.dropdown-wrapper.active ul {
  height: 100%;
}
.subject-tipe-header {
  font-size: 16px;
}

@media only screen and (min-width: 600px) {
  .contact-form-back-card {
    padding: 32px;
  }
  .form-action-bar {
    justify-content: space-between;
    flex-direction: row;
  }
  .subscribe-check-wrap {
    margin: 0 16px 0 0;
  }
  .subscribe-check-wrap p {
    font-size: inherit;
  }
  .contact-form-section input,
  .contact-form-section .dropdown,
  .contact-form-section textarea {
    border-radius: 16px;
    height: 56px;
    padding: 8px 16px;
    margin: 0 0 2px;
  }

  .contact-form-section textarea {
    margin: 0 0 16px;
  }
  .propjects-container-wrapper {
    margin-bottom: 8px;
  }

  .contact-first-line {
    flex-direction: row;
  }
  .contact-first-line > *:first-child {
    margin-right: 2px;
  }
}

@media only screen and (min-width: 900px) {
  .contact-form-back-card {
    padding: 48px;
  }
  .contact-form-section input,
  .contact-form-section .dropdown,
  .contact-form-section textarea {
    border-radius: 16px;
    padding: 8px 18px;
    margin: 0 0 8px;
  }
  .dropdown-wrapper ul {
    padding: 8px 0 18px;
  }
  .dropdown-wrapper ul li {
    height: 48px;
    padding-left: 18px;
  }
  .contact-form-section textarea {
    margin: 0 0 24px;
  }
  .propjects-container-wrapper {
    margin-bottom: 12px;
  }
  .contact-first-line > *:first-child {
    margin-right: 8px;
  }
}

@media only screen and (min-width: 1200px) {
  .contact-form-back-card {
    padding: 56px;
  }
  .contact-form-section input,
  .contact-form-section .dropdown,
  .contact-form-section textarea {
    border-radius: 16px;
    height: 64px;
    padding: 12px 24px;
    margin: 0 0 12px;
  }
  .dropdown-wrapper ul {
    padding: 8px 0 24px;
  }
  .dropdown-wrapper ul li {
    height: 48px;
    padding-left: 24px;
  }
  .contact-form-section textarea {
    margin: 0 0 24px;
  }
  .propjects-container-wrapper {
    margin-bottom: 16px;
  }
  .subject-tipe-header {
    font-size: 18px;
  }

  .contact-first-line > *:first-child {
    margin-right: 12px;
  }
}

