.page-section-header {
  margin-bottom: 48px;
  flex-direction: column;
}

.page-section span {
  text-transform: uppercase;
  font-size: 18px;
  opacity: 0.5;
  display: block;
  margin-bottom: 12px;
}

.page-section-header h3,
.page-section-header p {
  width: 100%;
  margin-bottom: 12px;
}
.page-section-header h3 {
  margin-top: -4px;
}
.page-section-header .header-2 {
  /* max-width: 500px; */
}
@media only screen and (min-width: 650px) {
  .page-section-header {
    margin-bottom: 64px;
    flex-direction: row;
  }
  .page-section-header h3,
  .page-section-header p {
    max-width: 500px;
    margin-bottom: 0;
  }
  .page-section-header h3 {
    max-width: none;
  }
}
