/* Font----------- */

a {
  color: inherit;
  text-decoration: none;
}

.App {
  text-align: center;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.App div {
  box-sizing: border-box;
}

ul {
  margin: 0;
  padding: 0;
}
input {
  box-shadow: inset 0px 0px 0px 0px red;
}

button {
  background: none;
  font-weight: 700;
  font-size: 16px;
  border: none;
  padding: 10px 25px;
  height: 40px;
  border-radius: 100px;
  cursor: pointer;
  outline: inherit;
  transition: 0.15s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  color: var(--highlight);
  white-space: nowrap;
}
button svg {
  height: 24px;
  width: 24px;
  margin-right: -8px;
}
button:hover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.09),
    0px 15px 25px rgba(0, 0, 0, 0.12);
  transform: scale(1.03);
}

button.line {
  border: 2px solid var(--highlight);
  color: var(--highlight);
  background: none;
}
button.line:hover {
  box-shadow: none;
  color: var(--tertiary-background);
  background: var(--highlight);
}
button.primary {
  background: var(--highlight);
  color: var(--tertiary-background);
}
button.secondary {
  background: white;
  color: var(--primary-font);
}

button.fog {
  /* backdrop-filter: blur(20px); */
  background: rgba(255, 255, 255, 0.8);
  color: var(--highlight);
}
button.fog:hover {
  background: white;
}

section,
footer {
  display: flex;
  justify-content: center;
  /* overflow-x: hidden; */
}

footer > *:last-child,
section > *:last-child {
  width: 100%;
  position: relative;
  z-index: 10;
  padding: 24px 16px;
  box-sizing: border-box;
}

.primary-section {
  background: var(--primary-background);
}

.secondary-section {
  background: var(--secondary-background);
}

/* common classes------------------ */
.page-section {
  padding-top: 64px;
  text-align: left;
}

/* Animations------------------ */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes drop-right {
  0% {
    transform: translatex(-16px);
  }
  100% {
    transform: translatey(0);
  }
}
@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translatey(80px);
  }
  100% {
    opacity: 1;
    transform: translatey(0);
  }
}
@keyframes fade-in-up-sml {
  0% {
    opacity: 0;
    transform: translatey(24px);
  }
  100% {
    opacity: 1;
    transform: translatey(0);
  }
}

@keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translatey(-80px);
  }
  100% {
    opacity: 1;
    transform: translatey(0);
  }
}
@keyframes fade-in-right {
  0% {
    opacity: 0;
    transform: translatex(-24px);
  }
  100% {
    opacity: 1;
    transform: translatey(0);
  }
}
@keyframes fade-in-left {
  0% {
    opacity: 0;
    transform: translatex(24px);
  }
  100% {
    opacity: 1;
    transform: translatey(0);
  }
}

.float-in-place {
  animation: float 7s ease-in-out infinite;
}
@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px) rotate(-8deg);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-8px) rotate(8deg);
  }

  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px) rotate(-8deg);
  }
}

/* Flexbox--------------------- */
.flex-just-cent {
  display: flex;
  justify-content: center;
}
.flex-just-btw {
  display: flex;
  justify-content: space-between;
}
.flex-just-rit {
  display: flex;
  justify-content: flex-end;
}
.flex-just-lft {
  display: flex;
  justify-content: flex-start;
}
.flex-align-cent {
  display: flex;
  align-items: center;
}
.flex-align-top {
  display: flex;
  align-items: flex-start;
}
.flex-col {
  display: flex;
  flex-direction: column;
}

/* mobile------------------------------- */
.desktop-only {
  display: none;
}

.mobile-only {
  display: flex;
}

/* Tablet---- */
@media only screen and (min-width: 600px) {
  footer > *:last-child,
  section > *:last-child {
    max-width: 800px;
    padding: 36px 16px;
  }

  /* mobile------------------------------- */
  .desktop-only {
    display: flex;
  }

  .mobile-only {
    display: none;
  }
}
/* Desktop---- */
@media only screen and (min-width: 900px) {
  footer > *:last-child,
  section > *:last-child {
    max-width: 1088px;
    padding: 64px 36px;
  }
  p {
    font-size: 18px;
    line-height: 30px;
  }
}
@media only screen and (min-width: 1200px) {
  footer > *:last-child,
  section > *:last-child {
    max-width: 1272px;
    padding: 36px 16px;
  }
  button {
    height: 48px;
  }
  button.header-button {
    height: 40px;
  }
}
