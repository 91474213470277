.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  left: 0;
  z-index: 200;
  transition: 0.15s ease;
  background: rgba(255, 255, 255, 0.3);
  animation: fade-in 0.2s ease forwards;
}

.modal-wrapper .testimonial-card {
  position: relative;
  z-index: 220;
  max-height: 300px;
  max-width: 400px;
  margin: 24px;
  animation: fade-in-up 0.4s ease forwards;
}

.sent-animation {
  animation: fade-out 2s ease-in-out forwards;
  animation-delay: 6s;
}
